<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{$t('common.person.dialog.edit.title')}}</h3>
        <h3 v-else>{{$t('common.person.dialog.add.title')}}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <PersonForm
            :form="form.person"
          />

          <AlertBar />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules from "@/mixins/formRules";

export default {
  name: "ContactDialog",

  mixins: [dialogMixin, formRules],

  components: {
    PersonForm: () => import("@/components/Common/Forms/PersonForm"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    base_url_api_add: { type: String, default: null },
    base_url_api_edit: { type: String, default: null },
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},
      defaultForm: {
        person: {
          gender: "OTHER",
          firstname: "",
          name: "",
          position: "",
          email: "",
          phone: ""
        }
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        if (this.item)
          this.submitEdit();
        else
          this.submitAdd();
      }
    },

    submitAdd() {
      this.$http.post(this.base_url_api_add, this.form, {
        headers: {Authorization: "Bearer " + this.$session.get('jwt')}
      })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.person.dialog.add.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },

    submitEdit() {

      this.$http
          .put(`${this.base_url_api_edit}/${this.item.id}`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('common.person.dialog.edit.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
